$color-black: #000;
$color-blue: #3EA1F5;
$color-blue-light: #0070BF;
$color-border: #f2f2f2;
$color-gray: #666;
$color-gray-dark: #999;
$color-gray-light: #EBEBEB;
$color-green: #417B43;
$color-green-light: #DFF0D8;
$color-orange: #FF7F00;
$color-red: #E60000;
$color-yellow-dark: #FAEBCC;
$color-yellow-light: #FCF8E3;
$color-white: #FFF;

$color-orange-hover: darken($color-orange, 10%);
$color-orange-active: darken($color-orange-hover, 10%);

$font-size-body: 13px;
$font-size-h2: 20px;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-book: 300;
$line-height-body: 20px;
$line-height-h2: 32px;

$bg-colors: ($color-blue, 'blue'), ($color-gray, 'gray'), ($color-white, 'white');
$text-colors: ($color-white, 'white'), ($color-orange, 'orange'), ($color-black, 'black'), ($color-gray-dark, 'placeholder'), ($color-red, 'red');

@each $current-color in $bg-colors {
    $color-var: nth($current-color, 1);
    $color-text: nth($current-color, 2);
    .bg-#{$color-text} {
        background-color: $color-var;
    }
}

@each $current-color in $text-colors {
    $color-var: nth($current-color, 1);
    $color-text: nth($current-color, 2);
    .text-#{$color-text} {
        color: $color-var;
    }
}
