
@mixin message-banner {
    margin-top: 24px;
    margin-bottom: 24px;
}

.message-banner--error {
    @include message-banner();
    background-color: $color-yellow-light;
    color: $color-red;
    border: solid 1px $color-yellow-dark;
}

.message-banner--success {
    @include message-banner();
    background-color: $color-green-light;
    color: $color-green;
    border: solid 1px $color-green;
}