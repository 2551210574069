.radio-option {
    @include box-spacing('padding', 'A', 'xs');
    position: relative;

    &:hover:not([disabled]):not(.input--error), &:focus:not([disabled]):not(.input--error) { /* Hover/Focus */
        border-color: $color-blue;
    }
    &[disabled] { /* Disabled */
        background-color: $color-gray-light;
        cursor: not-allowed;
    }

    > input {
        height: 20px;
        width: 20px;
        border-radius: 2px;
        border: 1px solid $color-gray;
        outline: none; 
        box-shadow: none;
        vertical-align: middle;
        position: absolute;
        margin-top: 2px;
    }

    > label {
        margin-left: 20px;
        padding-left: $box-md;
        line-height: 24px;
        vertical-align: middle;
        word-break: break-all;
        display: inline-block;
    }
}