%base-font {
    color: $color-black;	
    font-family: "Open Sans",Helvetica,Arial,sans-serif,Verdana;	
    font-size: 13px;	
    line-height: 20px;
}

body {
    @extend %base-font;
}

h2, .h2 {
    font-size: $font-size-h2;
    font-weight: $font-weight-book;
    line-height: $line-height-h2;
    margin: $box-n;
}

p, .p {
    font-size: $font-size-body;
    font-weight: $font-weight-regular;
    line-height: $line-height-body;
    margin: $box-n;
}

a, .link {
    color: $color-blue-light;
    font-size: $font-size-body;
    text-decoration: none;
    cursor: pointer;
    font-weight: $font-weight-regular;
}