

// mobile breakpoint
@media (max-width: 815px) {
    .fill-space--absolute {
        position: relative;
    }
    .flex-column {
        display: block;
    }
    .hidden--xs {
        display: none;
    }
    .overflow-auto {
        height: auto;
        overflow: hidden;
    }
    body {
        background-color: black;
    }
    .form-wrapper {
        width: 100%;
    }
    .auth0-lock-wrapper {
        margin-top: 0;
        width: 100%;

        .auth0-lock.auth0-lock {
            .auth0-lock-submit,
            .auth0-lock-header {
                border-radius: 0;
            }

            &.auth0-lock.auth0-lock-opened-in-frame {
                width: 100%;
                .auth0-lock-widget {
                    width: 100%;
                }
            }
        }
    }
    .auth0-lock-error-msg {
        color: #f00 !important;
        font-size: 12px !important;
        margin-top: 5px !important;
        white-space: nowrap !important;
        position: inherit !important;
        left: 0 !important;
        top: 0 !important;
        display: block !important;
        border-radius: 0 !important;
        box-shadow: none !important;

        .auth0-lock-error-invalid-hint {
            padding: 0 15px !important;
        }
    }

    .auth0-lock-error-msg:after {
        content: none !important;
    }

    .expired-invitation-wrapper {
        width: 100%;
        margin: 0;
    }

    .homepage-wrapper {
        width: 100%;
    }
}

@media (min-width: 816px) {
    .hidden--not-xs {
        display: none;
    }
    .auth0-lock.auth0-lock .auth0-lock-widget {
        overflow-x: visible !important;
    }
}

@media (min-width: 482px) {
    .auth0-lock-input-phone_number,
    .auth0-lock-input-username {
        .auth0-lock-error-msg {
            width: 325px;
        }
    }
}
