@import 'styles/custom-variables';
@import 'styles/utilities.scss';
@import 'styles/typography.scss';
@import "styles/components.scss";

body {
    background-color: $color-gray-light;
    margin: $box-n;
    height: 100%;
}

* {
    box-sizing: border-box;
}

.pf-header {
    background-color: #0B1424;
    border: none;
    padding: 12px 35px;
}
.pf-logo {
    margin: 0 auto;
}
.racing-stripe {
    width: 100%;
    min-height: 5px;
    background: #ec6707;
    overflow: hidden;
}
.racing-stripe__img {
    position: absolute;
    width: 100%;
    vertical-align: middle;
}
.racing-stripe__container {
    position: relative;
    max-width: 930px;
    padding: 0px;
    margin: 0 auto;
    width: 100%;
}
.homepage-image__img {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: fixed;
    object-fit: cover;
}
.form-wrapper {
    width: 816px;
    margin: 0 auto;
}
.homepage-wrapper {
    padding-top: 24px;
    width: 816px;
    margin: 0 auto;
}
.user-input-row {
    height: 100%;
    margin-top: 40px;
}
.blurb-row {
    min-height: 200px;
    background-color: white;
    margin-top: 24px;
    margin-bottom: 40px;
    border-radius: 5px;
}
.blurb-row__pipe {
    border-left: 2px solid #ebebeb;
}
.blurb-row__blurb {
    width: 451px;
}
.access-health-record-blurb {
    width: 400px;
}
.sign-up-blurb__list-icon {
    width: 30px;
}
.sign-up-blurb__list-text {
    width: 367px;
}
.mobile-blurbs {
    background: white;
    border: 2px white solid;
}
.mobile-blurbs .access-health-record-blurb {
    width: unset;
}
.footer {
    background: black;
}
.footer__text-container {
    padding: 0 25px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
}
.footer__text {
    font-size: 14px;
    color: $color-gray-dark;
}
.footer__text--link-color {
    color: $color-gray-dark;
}
.icon {
    height: 20px;
}
.sign-up-form__error-text-hint {
    margin-bottom: 1px;
}
.auth0-lock.auth0-lock .auth0-lock-header-logo.centered {
    width: 278px;
}
.auth0-lock.auth0-lock img.auth0-lock-header-logo {
    width: 278px;
}
.auth0-lock.auth0-lock .auth0-lock-widget {
    overflow-x: unset !important;
}
.auth0-lock.auth0-lock .auth0-lock-header {
    border-bottom: 1px solid #F2F2F2;
}
.auth0-lock.auth0-lock .auth0-lock-header-bg.auth0-lock-blur-support {
    background: white;
}
.auth0-lock.auth0-lock img.auth0-lock-header-logo {
    margin-top: 20px;
}
.auth0-lock-confirmation .auth0-lock-confirmation-content > span {
    display: block;
    padding-bottom: 16px;
}
.auth0-lock.auth0-lock .auth0-lock-widget-container .auth0-lock-confirmation {
    z-index: 0;
}
.auth0-lock.auth0-lock .auth0-lock-confirmation p.thank-you-header {
    margin-bottom: 8px;
}
.auth0-lock.auth0-lock .auth0-lock-confirmation p.verify-email {
    margin-bottom: 16px;
}
.auth0-lock-wrapper .auth0-lock.auth0-lock.auth0-lock.auth0-lock-opened-in-frame .auth0-lock-widget {
    max-height: unset;
}
.auth0-lock-input-phone_number,
.auth0-lock-input-username {
    .auth0-lock-error-msg {
        white-space: normal;
    }
}
.auth0-lock-name {
    display: none;
}
.auth0-lock-header {
    z-index: 10;
}
.auth0-lock-header-bg-solid {
    display: none !important;
}
.auth0-lock-wrapper {
    margin-top: 64px;
}
.hide-content {
    display: none !important;
}
.auth0-lock-alternative-link {
    color: $color-blue-light !important;
}

@import "styles/responsive.scss";
@import "styles/scopes.scss";
@import "styles/launch-patient.scss";