.check-box {
    @include box-spacing('padding', 'A', 'xs');

    &:hover:not([disabled]):not(.input--error), &:focus:not([disabled]):not(.input--error) { /* Hover/Focus */
        border-color: $color-blue;
    }
    &[disabled] { /* Disabled */
        background-color: $color-gray-light;
        cursor: not-allowed;
    }

    > input {
        height: 20px;
        width: 20px;
        border-radius: 2px;
        border: 1px solid $color-gray;
        outline: none; 
        box-shadow: none;
        line-height: 24px;
        vertical-align: middle;
    }

    > label {
        padding-left: $box-md;
        line-height: 24px;
        vertical-align: middle;
    }
}