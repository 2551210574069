.text-field {
    color: $color-black;
    border: 1px solid $color-gray;
    border-radius: 2px;
    box-shadow: none;
    display: block;
    height: auto;
    font-size: $font-size-body;
    font-weight: $font-weight-regular;
    line-height: $line-height-body;
    outline: none; 
    padding: $box-xs $box-xs $box-xs $box-md;
    position: relative;
    transition: border-color ease-in-out .15s;
    width: 100%;
    &::-webkit-clear-button,
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button,
    &::-webkit-calendar-picker-indicator { /* Removes spinner for webkit browsers like Safari and Chrome */
        -webkit-appearance: none;
        display: none;
    }
    &::-webkit-input-placeholder { /* Placeholder - Chrome/Opera/Safari */
        color: $color-gray-dark;
    }
    &::-moz-placeholder { /* Placeholder - Firefox 19+ */
        color: $color-gray-dark;
        opacity: 1;
    }
    &:-ms-input-placeholder { /* Placeholder - IE 10+ */
        color: $color-gray-dark;
    }
    &:-moz-placeholder { /* Placeholder - Firefox 18- */
        color: $color-gray-dark;
        opacity: 1;
    }
    &:hover:not([disabled]):not(.input--error), &:focus:not([disabled]):not(.input--error) { /* Hover/Focus */
        border-color: $color-blue;
        box-shadow: none;
    }
    &[disabled] { /* Disabled */
        background-color: $color-gray-light;
        cursor: not-allowed;
    }
    &[type='number'] { /* Removes number spinner for Firefox */
        -moz-appearance: textfield;
    }
    &.input--error { /* Error */
        @include calc(background-position, "100% - #{$box-sm}");
        border-color: $color-red;
        color: $color-red;
        box-shadow: none;
        background-size: $font-size-body $font-size-body;
    }
}