.expired-invitation-center-message {
    background-color: #FF3E00;
    text-align: center;
    vertical-align: center;
    color:white;
    padding: 20px 42px;
    font-size: 14px;
    font-weight: 500px;
}

.expired-invitation-bottom-message {
    text-align: center;
    vertical-align: center;
    color: black;
    background-color: white;
    padding: 23px 23px 54px;
    border-radius: 5px;
    .header-text {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 10px;
    }
}

@import "../responsive.scss";