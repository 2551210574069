.launch-patient-instructions {
    @include box-spacing('margin', 'A', 'lg');
    @include box-spacing('padding', 'A', 'xl');
    min-height: 200px;
    background-color: white;
    margin-top: 24px;
    margin-bottom: 40px;
    border-radius: 5px;

    > p {
        @include box-spacing('margin', 'B', 'lg');
    }
}

.faux-auth0-panel.launch-patient__form-container {
    height: max-content;
    min-height: 340px;
    margin-top: 24px;
}

.faux-auth0-panel__body.launch-patient__form-body {
    @include box-spacing('padding', 'LR', 'xl');
    @include box-spacing('padding', 'TB', 'lg');
    min-height: 160px;
    text-align: left;
}

.continue-button--with-disable-state:disabled {
    background-color: $color-gray;
    pointer-events: none;
}

.launch-patient--hidden {
    display: none;
}
