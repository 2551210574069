%btn--base {
    border: 1px solid transparent;
    border-radius: $box-xs;
    padding: $box-xs $box-lg;
    font-weight: $font-weight-semibold;
    font-size: $font-size-body;
    line-height: $line-height-body;
    outline: none;
    text-shadow: none;
    &:hover, &:focus {
        outline: none;
    }
    &:disabled {
        cursor: not-allowed;
        &, &:hover, &:focus {
            background-color: #f2f2f2;
            border-color: $color-gray;
            color: $color-gray-dark;
        }
    }
}

.btn--primary {
    @extend %btn--base;
    color: $color-white;
    background-color: $color-orange;
    border-color: $color-orange;

    &:hover, &:focus {
        background-color: $color-orange-hover;
        border-color: $color-orange-hover;
    }
    &:active {
        background-color: $color-orange-active;
        border-color: $color-orange-active;
    }
}

.btn--link {
    @extend %btn--base;
    border: none;
    background: transparent;
    color: $color-blue-light;
    padding: 0;

    &:hover, &:focus {
        border: none;
        color: $color-blue
    }
    &:disabled {
        cursor: not-allowed;
        &, &:hover, &:focus {
            border: none;
            color: $color-gray-dark;
        }
    }
}
