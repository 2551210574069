@import '../custom-variables.scss';

// The styles in this file aim to mimic the auth0 lock widget styles to support custom login functionality
.faux-auth0-panel {
    background: $color-white;
    border-radius: 6px;
    margin-top: 64px;
    width: 300px;
    height: 340px;
}
.faux-auth0-panel__header {
    padding: 34px 11px 28px 11px;
    border-bottom: 1px solid $color-border;
}
.faux-auth0-panel__header-logo {
    height: 58px;
    width: 278px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.faux-auth0-panel__body {
    text-align: center;
    padding-top: 30px;
}
.faux-auth0-panel__header-message {
    margin-bottom: 8px;
    padding-top: 4px;
    font-weight: bold;
    color: $color-gray;
}
.faux-auth0-panel__message {
    padding: 0 34px;
    color: $color-gray;
}
.faux-auth0-panel__continue-button {
    border: 0;
    padding: 14px;
    width: 100%;
    height: 60px;
    overflow: hidden;
    border-radius: 0 0 5px 5px;
    display: inline-block;
    color: $color-white;
    background-color: #ff7f00;
    letter-spacing: 1px;
    font-size: 14px;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
}
.faux-auth0-panel__email-field {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAZ9JREFUOBGVU7uKwkAUPXmID5AttNyFYBGwsLGwFBUFF/wOhfyE5jPcxkZt/IHFxg+wsZJtrFwS8NWIohZm545xNkp8XcjMnbnnnJk790YyTfPTcZwm+z7whEmSNGWwaqPR+Ca4/AqZCO5BX+STkcBTJ5/gp9HLkb2BR34kEoGu6xewlwQ0TUOxWPQXCIVCIhAMBsEeS6y9MbHpOirNlUoF6XQanU4Hq9UKhmHAsiy0Wq2L2DWZ1i+l4Ccg1et1hwJ0zd1uxzGUwn6/98OLPZbiL1vUxA3OZEI8IhOGlfKdTU3+BrThZ5lMBoVCAev1Gr1eD7PZDIFAALIs80NIRNzAT4DIw+EQm80G2WyWQ1KpFHK5nICr1NvezhIR5iyXSyQSCUSjUSiKgnK5jGQyCVVVEYvF0O12oeTz+R+GJfk3L5n8yWTC+yEej3OxwWCA4/GI7XaLfr/P0/jvlis2VadUKvH+IFK73YZt2yCxcDiM6ZR+SuDuI45GI4zHY8zncxwOB05YLBZ8Pg83BajOjEilummEuVeFmtssvgJurPYHGEKbZ/T0eqIAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 16px 18px;
    background-position: 98% 50%;
    cursor: auto;
}
.faux-auth0-panel__input-container {
    display: flex;
    width: 100%;
}
.faux-auth0-panel__input-email-icon {
    padding: 10px;
    height: 40px;
    background: $color-border;
    color: $color-gray;
    min-width: 50px;
    text-align: center;
}
.faux-auth0-wrapper {
    margin-top: 64px;
    background: $color-white;
    border-radius: 6px;
    width: 300px;
}
.input--error {
    color: #f00;
    font-size: 12px;
}
.faux-auth0-wrapper-mobile {
    margin-top: 0;
    width: 100%;
}
.faux-auth0-opened-in-frame,
.faux-auth0-with-tabs {
    opacity: 1;
    pointer-events: auto;
    position: relative;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}
.faux-auth0-pflogo {
    background-image: url(/assets/images/icon-pf-logo.svg);
}
.faux-auth0-input {
    border: 0;
    padding: 0 14px;
    right: 0;
    font-size: 13px;
    width: 100%;
    border-radius: 0 6px 6px 0;
    box-sizing: border-box;
    position: relative;
}
.faux-auth0-input-x {
    border: 0;
    padding: 10px 0 0 0;
    right: 0;
    font-size: 13px;
    width: 50px;
    border-radius: 6px 0 0 6px;
    box-sizing: border-box;
    position: relative;
}
.faux-auth0-input-wrap {
    border: 1px solid;
    border-color: #ccc;
    transition: 0.3s ease-in-out;
    box-sizing: initial;
    border-radius: 6px;
}
.faux-auth0-input-wrap-with-icon {
    padding-left: 40px;
}
.faux-auth0-input-block {
    position: relative;
    margin-bottom: 15px;
}

@media (max-width: 815px) {
    .faux-auth0-wrapper {
        margin-top: 0;
        width: 100%;
    }
    .faux-auth0-panel {
        background: $color-white;
        border-radius: 6px;
        width: 100%;
    }
    .faux-auth0-opened-in-frame,
    .faux-auth0-with-tabs {
        width: 100%;
    }
    .faux-auth0-center {
        vertical-align: middle;
        //display: table-cell;
        margin: auto;
        width: 100%;
    }
    .faux-auth0-input-block {
        margin-bottom: 10px;
    }
}
