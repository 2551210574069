$box-n: 0px;
$box-xs: 2px;
$box-sm: 4px;
$box-md: 8px;
$box-lg: 16px;
$box-xl: 32px;

$box-sizes: (
    n: $box-n,
    xs: $box-xs,
    sm: $box-sm,
    md: $box-md,
    lg: $box-lg,
    xl: $box-xl
);

$box-directions: (
    A: ('top', 'right', 'bottom', 'left'),
    TB: ('top', 'bottom'),
    LR: ('left', 'right'),
    T: ('top'),
    R: ('right'),
    B: ('bottom'),
    L: ('left')
);

$box-types: 'margin', 'padding';

@mixin box-spacing($box-type, $directionKey, $sizeKey) {
    @each $direction in map-get($box-directions, $directionKey) {
        #{$box-type}-#{$direction}: map-get($box-sizes, $sizeKey);
    }
}

@each $box-type in $box-types {
    @each $box-direction in map-keys($box-directions) {
        @each $box-size in map-keys($box-sizes) {
            .#{$box-type}-#{$box-direction}#{$box-size} {
                @include box-spacing($box-type, $box-direction, $box-size);
            }
        }
    }
}

@mixin inline-flex-group-item {
    flex: 1;
    &:first-child {
        padding-left: 0px;
    }
    &:last-child {
        padding-right: 0px;
    }
    &.item-fixed{
        flex: 0 1 auto;
    }
}

.inline-flex-group {
    display: flex;
}

@each $box-size in map-keys($box-sizes) {
    .inline-flex-group__item--#{$box-size} {
        @include inline-flex-group-item();
        @include box-spacing('padding', 'TB', map-get($box-sizes, 'sm'));
        @include box-spacing('padding', 'LR', $box-size);
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-grow {
    position: relative;
    flex: 1 0 400px;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.fill-space--absolute {
    height: 100%;
    width: 100%;
    position: absolute;
}

.fill-space--relative {
    height: 100%;
    position: relative;
}

.text--nowrap {
    white-space: nowrap;
}

.text--wrap {
    word-wrap: break-word;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text--truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.text-small {
    font-size: 11px;
    line-height: 20px;
}
.hidden {
    display: none;
}
.overflow-auto {
    height: 100%;
    overflow: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.break-word {
    word-break: break-word;
}

@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}
