.scope-selection {
    background: white;
    border: 2px white solid;
}
.scopes-list {
    @include box-spacing('margin', 'A', 'md');
    @include box-spacing('padding', 'T', 'md');

    .check-box {
        @include box-spacing('margin', 'B', 'md');
        position: relative;
        
        > input {
            vertical-align: top;
            display: block;
            position: absolute;
        }
    
        > label {
            line-height: 20px;
            display: block;
            margin-left: 20px;
            max-width: 500px;

            .scope-title {
                font-weight: bold;
                color: #333;
            }
        }
    }
}

// mobile breakpoint
@media (max-width: 815px) {
    .scope-selection {
        align-content: left;
        text-align: left;
        
        .scopes-list {
            width: 400px;
            text-align: left;
        }
    }
}
