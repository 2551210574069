.email-error-racing-stripe {
    width: 100%;
    min-height: 5px;
    background: #ec6707;
    overflow: hidden;
    position: relative;
}

.email-error-racing-stripe__img {
    position: absolute;
    width: 60%;
    vertical-align: middle;
    left: 20%;
}

.email-error-racing-stripe__container {
    position: relative;
    max-width: 930px;
    padding: 0px;
    margin: 0 auto;
    width: 100%;
}

.message-logo {
    padding: 10px 5px 5px;
}

.message-content {
    padding: 30px 30px;
}

.content-title {
    font-size: 22px;
    color: #004397;
    margin-bottom:20px
}

.content-font {
    font-size: 16px;
    color: #646464;
    line-height: 22px;
}

.content-link {
    font-size: 16px;
}

.content-list {
    li {
        padding-left: 10px;
        margin-bottom: 10px;
    }
}